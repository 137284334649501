<template>
  <div class="main">
    <h1 class="title" @click="getRePlay">玄源AI</h1>
    <Transition name="slide-up">
      <div class="container bg1" key="1" v-if="flag == 1">
        <button class="create-btn" @click="goCopy">声音生成</button>
        <div class="center">
          <h1>声音复制系统</h1>
          <p>SOUND REPLICATION SYSTEM</p>
        </div>
        <div class="tips-info">
          <p class="tip-title">提示</p>
          <div class="tip">
            <img src="../assets/1.png" alt="" />务必在安静的地方录制声音
          </div>
          <div class="tip">
            <img src="../assets/2.png" alt="" />以自然放松的语气朗读文本
          </div>
          <div class="tip">
            <img src="../assets/3.png" alt="" />请勿使用未经授权的声音
          </div>
          <div class="tip">
            <img
              src="../assets/4.png"
              alt=""
            />此页面为声音复制体验端口，不会储存声音特征文件
          </div>
        </div>
        <button class="start-btn" @click="flag = 2">继续</button>
      </div>
      <div class="container bg2" key="2" v-else-if="flag == 2">
        <button v-if="showRec" class="create-btn" @click="recOpen">
          打开录音权限
        </button>
        <div class="center">
          <h1>请朗读文本</h1>
          <div class="text">
            <p>这段时间天气不错，</p>
            <p>晚上散散步，</p>
            <p>吹吹风，</p>
            <p>听听音乐，</p>
            <p>心情好了不少，</p>
            <p>真舒服啊！</p>
          </div>
        </div>
        <div class="btn-box">
          <img
            v-if="isStarting"
            class="start-open"
            src="../assets/open.png"
            alt=""
            @click="recStart"
          />
          <img
            v-else
            class="start-stop"
            src="../assets/stop.png"
            alt=""
            @click="recStop"
          />
        </div>
        <div class="time">{{ durationTxt }}</div>
      </div>
      <div class="container bg2 bg3" key="3" v-else-if="flag == 3">
        <div class="center">
          <h1>正在复制声音特征</h1>
          <div class="text">
            <p>声音特征正在处理，请等待</p>
            <p>并保持窗口大概不要关闭</p>
          </div>
        </div>
        <loading-spinner v-if="isLoading"></loading-spinner>
        <div class="loading" v-if="isSuccess">声音复制成功</div>
        <div class="loading" v-if="copyMes">{{ copyMes }}</div>
        <button class="start-btn" v-if="isSuccess" @click="goCopy">
          立即体验
        </button>
        <button
          class="start-btn"
          v-if="!isSuccess && copyMes"
          @click="flag = 2"
        >
          返回重试
        </button>
      </div>
      <div class="container bg2 bg3" key="4" v-else-if="flag == 4">
        <div class="center">
          <h1>声音复制效果体验</h1>
        </div>
        <textarea
          class="input-box"
          v-model="message"
          placeholder="请输入要生成的文字内容。"
          cols="30"
          rows="10"
        ></textarea>
        <button class="start-btn" @click="recTts" :disabled="isTtsing">
          立即生成
        </button>
        <loading-spinner v-if="isTtsing"></loading-spinner>
        <div id="waveform" class="waveform"></div>
        <div class="radio-box" v-if="mp3Url">
          <!-- <audio ref="LogAudioPlayer" style="width:100%;margin: 80px auto 16px;"></audio> -->
          <img
            class="start-bt"
            v-if="!isPlaying"
            src="../assets/start.png"
            alt=""
            @click="getPlay(true)"
          />
          <img
            class="start-bt"
            v-else
            src="../assets/stop.png"
            alt=""
            @click="getPlay(false)"
          />
          <button class="re-start" @click="getRePlay">重新开始</button>
        </div>
      </div>
    </Transition>
    <slot name="top"></slot>
    <div class="mainBox">
      <div class="pd">
        类型：{{ type }}
        <span style="margin: 0 20px">
          比特率:
          <input type="text" v-model="bitRate" style="width: 60px" /> kbps
        </span>
        采样率:
        <input type="text" v-model="sampleRate" style="width: 60px" /> hz
      </div>

      <div class="btns">
        <div>
          <button @click="recOpen">打开录音,请求权限</button>
        </div>

        <button @click="recStart">录制</button>
        <button @click="recStop" style="margin-right: 20px">停止</button>
        <span style="display: inline-block">
          <button @click="recPlayLast">播放</button>
          <button @click="recUploadLast">上传</button>
          <button @click="recTts">生成</button>
        </span>
      </div>
    </div>
    <div class="mainBox">
      <div
        style="
          height: 100px;
          width: 300px;
          border: 1px solid #ccc;
          box-sizing: border-box;
          display: inline-block;
          vertical-align: bottom;
        "
        class="ctrlProcessWave"
      ></div>
      <div
        style="
          height: 40px;
          width: 300px;
          display: inline-block;
          background: #999;
          position: relative;
          vertical-align: bottom;
        "
      >
        <div
          class="ctrlProcessX"
          style="height: 40px; background: #0b1; position: absolute"
          :style="{ width: powerLevel + '%' }"
        ></div>
        <div
          class="ctrlProcessT"
          style="padding-left: 50px; line-height: 40px; position: relative"
        >
          {{ durationTxt + "/" + powerLevel }}
        </div>
      </div>
    </div>
    <div class="mainBox">
      <!-- 放一个 <audio ></audio> 播放器，标签名字大写，阻止uniapp里面乱编译 -->
      <!-- <AUDIO ref="LogAudioPlayer" style="width:100%"></AUDIO> -->

      <div class="mainLog">
        <div v-for="obj in logs" :key="obj.idx">
          <div
            :style="{
              color:
                obj.color == 1 ? 'red' : obj.color == 2 ? 'green' : obj.color,
            }"
          >
            <!-- <template v-once> 在v-for里存在的bug，参考：https://v2ex.com/t/625317 -->
            <span v-once>[{{ getTime() }}]</span><span v-html="obj.msg" />

            <template v-if="obj.res">
              {{ intp(obj.res.rec.set.bitRate, 3) }}kbps
              {{ intp(obj.res.rec.set.sampleRate, 5) }}hz 编码{{
                intp(obj.res.blob.size, 6)
              }}b [{{ obj.res.rec.set.type }}]{{ obj.res.durationTxt }}ms

              <button @click="recdown(obj.idx)">下载</button>
              <button @click="recplay(obj.idx)">播放</button>

              <span v-html="obj.playMsg"></span>
              <span v-if="obj.down">
                <span style="color: red">{{ obj.down }}</span>

                没弹下载？试一下链接或复制文本<button
                  @click="recdown64(obj.idx)"
                >
                  生成Base64文本
                </button>

                <textarea
                  v-if="obj.down64Val"
                  v-model="obj.down64Val"
                ></textarea>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
//加载必须要的core，demo简化起见采用的直接加载类库，实际使用时应当采用异步按需加载
import Recorder from "recorder-core"; //注意如果未引用Recorder变量，可能编译时会被优化删除（如vue3 tree-shaking），请改成 import 'recorder-core'，或随便调用一下 Recorder.a=1 保证强引用
//需要使用到的音频格式编码引擎的js文件统统加载进来，这些引擎文件会比较大
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
//可选的扩展
import "recorder-core/src/extensions/waveview";
import WaveSurfer from "wavesurfer.js";
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  name: "HelloWorld",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      url: "http://172.16.50.82:5005",
      flag: 1,
      copyMes: "",
      isLoading: true,
      type: "mp3",
      bitRate: 16,
      sampleRate: 44100,
      duration: 0,
      durationTxt: "00:00",
      powerLevel: 0,
      logs: [],
      rec: null,
      wave: null,
      isStarting: true,
      isSuccess: false,
      isTtsing: false,
      message:
        "玄源科技是一家优秀的AI赛道创业企业，在个性化大模型，儿童AI陪伴方面有非常领先的技术优势，其中我特别喜欢他们的第一款产品源宝机器人。",
      isPlaying: false,
      mp3Url: "",
      showRec: false,
      wavesurfer: null,
    };
  },
  created() {
    if (window.location.href.includes("voice")) {
      this.url = "https://voice-api.ai.bigs.top";
    }
    this.rec = Recorder;
    this.recOpen();
  },
  methods: {
    btnPlay() {
      console.log("this.wavesurfer", this.wavesurfer);
      this.wavesurfer.play();
    },
    btnPause() {
      this.wavesurfer.pause();
    },
    // 跳转合成页面
    goCopy() {
      this.flag = 4;
      this.recClose();
    },
    // 播放音频
    getPlay(bool) {
      this.isPlaying = bool;
      if (bool) {
        this.wavesurfer.play();
      } else {
        this.wavesurfer.pause();
      }
    },
    getRePlay() {
      this.flag = 1;
      this.isTtsing = false;
      this.mp3Url = "";
      this.recOpen();
    },
    // 关闭录取权限
    recClose() {
      let rec = this.rec;
      this.rec = null;
      if (rec) {
        rec.close();
        this.reclog("已关闭");
      } else {
        this.reclog("未打开录音", 1);
      }
    },
    // 打开录音权限
    recOpen() {
      let rec = (this.rec = Recorder({
        type: this.type,
        bitRate: +this.bitRate,
        sampleRate: +this.sampleRate,
        onProcess: (buffers, powerLevel, duration) => {
          this.duration = duration;
          this.durationTxt = this.formatMs(duration, 1);
          this.powerLevel = powerLevel;
          // this.wave.input( buffers[ buffers.length - 1 ], powerLevel, sampleRate );
        },
      }));
      console.log("red===>", rec);
      rec.open(
        () => {
          this.reclog(
            "已打开:" +
              this.type +
              " " +
              this.sampleRate +
              "hz " +
              this.bitRate +
              "kbps",
            2
          );
          // this.wave = Recorder.WaveView({ elem: ".ctrlProcessWave" });
        },
        (msg, isUserNotAllow) => {
          this.showRec = true;
          alert("录音权限打开失败：" + msg);
          this.reclog(
            (isUserNotAllow ? "UserNotAllow，" : "") + "打开失败：" + msg,
            1
          );
        }
      );
    },
    // 开始录音
    recStart() {
      if (!this.rec || !Recorder.IsOpen()) {
        this.showRec = true;
        this.reclog("未打开录音", 1);
        alert("未打开录音");
        return;
      }
      this.rec.start();
      this.isStarting = !this.isStarting;

      var set = this.rec.set;
      this.reclog(
        "录制中：" +
          set.type +
          " " +
          set.sampleRate +
          "hz " +
          set.bitRate +
          "kbps"
      );
    },
    // 结束录音
    recStop() {
      if (!(this.rec && Recorder.IsOpen())) {
        this.reclog("未打开录音", 1);
        return;
      }
      this.isStarting = !this.isStarting;
      var rec = this.rec;
      rec.stop(
        (blob, duration) => {
          this.reclog("已录制:", "", {
            blob: blob,
            duration: duration,
            durationTxt: this.formatMs(duration),
            rec: rec,
          });
        },
        (s) => {
          this.reclog("录音失败：" + s, 1);
        }
      );
    },

    recPlayLast() {
      if (!this.recLogLast) {
        this.reclog("请先录音，然后停止后再播放", 1);
        return;
      }
      this.recplay(this.recLogLast.idx);
    },
    recUploadLast() {
      this.flag = 3;
      this.isSuccess = false;
      if (!this.recLogLast) {
        this.reclog("请先录音，然后停止后再上传", 1);
        return;
      }
      this.copyMes = "";
      this.isLoading = true;
      var blob = this.recLogLast.res.blob;
      var onreadystatechange = (xhr, title) => {
        return () => {
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              this.reclog(
                title +
                  "上传成功" +
                  ' <span style="color:#999">response: ' +
                  xhr.responseText +
                  "</span>",
                2
              );
              const blob = JSON.parse(xhr.response);
              console.log("blob===>", blob);
              if (blob.BaseResp.StatusCode === 0) {
                console.log("上传成功：" + blob);
                this.isSuccess = true;
              } else {
                this.copyMes = "声音复制失败";
              }
            } else {
              this.reclog(
                title +
                  "没有完成上传，演示上传地址无需关注上传结果，只要浏览器控制台内Network面板内看到的请求数据结构是预期的就ok了。",
                "#d8c1a0"
              );
              this.copyMes = "声音复制失败";
              console.error(title + "上传失败", xhr.status, xhr.responseText);
            }
            this.isLoading = false;
          }
        };
      };
      this.reclog("开始上传到" + this.url + "，请稍候...");

      /***方式一：将blob文件转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传***/
      var reader = new FileReader();
      reader.onloadend = () => {
        var postData = {
          audio: (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result) || [])[1],
          text: "这段时间天气不错，晚上散散步，吹吹风，听听音乐，心情好了不少，真舒服啊！",
        };
        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.url + "/train");
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onreadystatechange = onreadystatechange(
          xhr,
          "上传方式一【Base64】"
        );
        xhr.send(JSON.stringify(postData));
      };
      reader.readAsDataURL(blob);
    },
    recTts() {
      if (!this.message) {
        return false;
      }
      var url = this.url + "/tts";
      var data = {
        text: this.message,
      };
      this.isTtsing = true;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const urlCreator = window.URL || window.window.webkitURL;
          this.mp3Url = urlCreator.createObjectURL(blob);

          // const a = document.createElement("a");
          // a.href = mp3Url;
          // a.download = "your-mp3-file.mp3";
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
          this.$nextTick(() => {
            // var audio = this.$refs.LogAudioPlayer;
            // audio.controls=true;
            // if (!(audio.ended || audio.paused)) {
            //   audio.pause();
            // }
            // audio.onerror =  (e) => {
            //   console.log('e===>', e)
            // };
            // audio.src = this.mp3Url;
            this.wavesurfer = WaveSurfer.create({
              container: "#waveform",
              height: 85,
              waveColor: "#39CFB1",
              progressColor: "#39CFB1",
              cursorColor: "#FFFFFF",
              url: this.mp3Url,
            });
          });
        } else {
          alert("生成失败，状态码：" + xhr.status);
          console.error("请求失败，状态码：" + xhr.status);
        }
        this.isTtsing = false;
      };
      xhr.onerror = () => {
        this.isTtsing = false;
        alert("请求失败，网络错误");
        console.error("请求失败，网络错误");
      };
      xhr.send(JSON.stringify(data));
    },

    reclog(msg, color, res) {
      var obj = {
        idx: this.logs.length,
        msg: msg,
        color: color,
        res: res,
        playMsg: "",
        down: 0,
        down64Val: "",
      };
      if (res && res.blob) {
        this.recLogLast = obj;
      }
      this.logs.splice(0, 0, obj);
      if (msg === "已录制:") {
        this.recUploadLast();
      }
    },
    recplay(idx) {
      var o = this.logs[this.logs.length - idx - 1];
      console.log(
        "%c [ o ]-390",
        "font-size:13px; background:pink; color:#bf2c9f;",
        o
      );
      o.play = (o.play || 0) + 1;
      var logmsg = (msg) => {
        o.playMsg =
          '<span style="color:green">' +
          o.play +
          "</span> " +
          this.getTime() +
          " " +
          msg;
      };
      logmsg("");

      var audio = this.$refs.LogAudioPlayer;
      audio.controls = true;
      if (!(audio.ended || audio.paused)) {
        audio.pause();
      }
      audio.onerror = (e) => {
        console.log(
          "%c [ e ]-370",
          "font-size:13px; background:pink; color:#bf2c9f;",
          e
        );
        logmsg(
          '<span style="color:red">播放失败[' +
            audio.error.code +
            "]" +
            audio.error.message +
            "</span>"
        );
      };
      audio.src = (window.URL || window.webkitURL).createObjectURL(o.res.blob);
      audio.play();
    },
    recdown(idx) {
      var o = this.logs[this.logs.length - idx - 1];
      o.down = (o.down || 0) + 1;

      o = o.res;
      var name =
        "rec-" +
        o.duration +
        "ms-" +
        (o.rec.set.bitRate || "-") +
        "kbps-" +
        (o.rec.set.sampleRate || "-") +
        "hz." +
        (o.rec.set.type || (/\w+$/.exec(o.blob.type) || [])[0] || "unknown");
      var downA = document.createElement("A");
      downA.href = (window.URL || window.webkitURL).createObjectURL(o.blob);
      downA.download = name;
      downA.click();
    },
    recdown64(idx) {
      var o = this.logs[this.logs.length - idx - 1];
      var reader = new FileReader();
      reader.onloadend = () => {
        o.down64Val = reader.result;
      };
      reader.readAsDataURL(o.res.blob);
    },
    getTime() {
      var now = new Date();
      var t =
        ("0" + now.getHours()).substr(-2) +
        ":" +
        ("0" + now.getMinutes()).substr(-2) +
        ":" +
        ("0" + now.getSeconds()).substr(-2);
      return t;
    },
    formatMs(ms, all) {
      var ss = ms % 1000;
      ms = (ms - ss) / 1000;
      var s = ms % 60;
      ms = (ms - s) / 60;
      var m = ms % 60;
      ms = (ms - m) / 60;
      var h = ms;
      var t =
        (h ? h + ":" : "") +
        (all || h + m ? ("0" + m).substr(-2) + ":" : "") +
        (all || h + m + s ? ("0" + s).substr(-2) + "" : "");
      return t;
    },
    intp(s, len) {
      s = s == null ? "-" : s + "";
      if (s.length >= len) return s;
      return ("_______" + s).substr(-len);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  width: 100%;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  background-color: #212a49;
  color: #ffd60a;
}
.main .title {
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #ffffff;
  z-index: 1;
  font-weight: 400;
}
.bg1 {
  background: url(../assets/bg1.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 812px;
  padding: 25px 30px 0;
  text-align: center;
}
.container .create-btn {
  position: absolute;
  top: 25px;
  right: 30px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  background: 0;
  cursor: pointer;
}
.container .center {
  margin-top: 354px;
}
.container .center h1 {
  font-weight: bold;
  font-size: 30px;
}
.container .center p {
  margin-top: 10px;
  font-size: 15px;
}
.container .tips-info {
  margin-top: 25px;
  text-align: left;
}
.container .tips-info .tip-title {
  font-size: 14px;
}
.container .tips-info .tip {
  width: 100%;
  background: rgba(255, 255, 255, 0);
  border-radius: 8px;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  font-size: 11px;
  padding: 10px 8px;
  margin-top: 10px;
  color: #ffffff;
}
.container .tips-info .tip img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.container .start-btn {
  width: 155px;
  height: 33px;
  background: #ffd60a;
  border-radius: 7px;
  border: 0;
  font-size: 14px;
  margin-top: 70px;
}
.bg2 {
  background: url(../assets/bg2.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.container.bg2 .center {
  margin-top: 40px;
}
.container.bg2 .center h1 {
  font-weight: bold;
  font-size: 30px;
}
.container.bg2 .center .text {
  margin-top: 35px;
}
.container.bg2 .center p {
  margin-top: 15px;
  font-size: 12px;
  color: #ffffff;
}
.btn-box {
  margin: 150px auto 0;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../assets/open-bg.png) no-repeat;
  background-size: 100% 100%;
}
.btn-box .open-bg {
  width: 100%;
  height: 100%;
}
.btn-box img {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.time {
  margin: 10px auto;
  font-size: 15px;
  color: #ffffff;
}
.bg3 {
  background: url(../assets/bg3.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.bg3 .loading {
  margin: 80px auto 0;
  font-size: 30px;
  color: #ffffff;
}
.bg3 .start-btn {
  margin-top: 25px;
}
.input-box {
  margin: 50px auto 0;
  width: 100%;
  height: 150px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 30px;
}
.input-box::placeholder {
  color: #ffffff;
}
.input-box:focus-visible {
  outline: 0;
}
.radio-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.radio-box .start-bt {
  width: 35px;
  height: 35px;
  margin-top: 20px;
}
.radio-box .re-start {
  margin-top: 70px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  background: 0;
}
.waveform {
  width: 100%;
  margin: 80px auto 20px;
}
.mainBox {
  margin-top: 12px;
  padding: 12px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #0b1;
  box-shadow: 2px 2px 3px #aaa;
  display: none;
}
.btns button {
  display: inline-block;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background: #0b1;
  color: #fff;
  padding: 0 15px;
  margin: 3px 20px 3px 0;
  line-height: 36px;
  height: 36px;
  overflow: hidden;
  vertical-align: middle;
}
.btns button:active {
  background: #0a1;
}
.pd {
  padding: 0 0 6px 0;
}
.lb {
  display: inline-block;
  vertical-align: middle;
  background: #00940e;
  color: #fff;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 99px;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}
.slide-up-enter-from {
  opacity: 0;
  transform: translateX(330px);
}
.slide-up-leave-to {
  opacity: 0;
  transform: translatex(-330px);
}
</style>
